import React from 'react';

import { Header } from 'components';
import { HomeBg, HomeContent, HomeInner } from './home.styles';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import theme, { palette } from 'theme';
import { Link } from 'react-router-dom';

import svpBg from 'assets/images/svp-bg.jpg';

const Home = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Header />
      <Stack direction={isMobile ? 'column' : 'row'} sx={{ height: `calc(100vh - 80px)` }}>
        <HomeBg>
          <img className="logo" src={svpBg} alt="Sandviper uniform" />
        </HomeBg>

        <HomeContent justifyContent="center">
          <HomeInner spacing={2} alignItems="flex-start">
            <Typography variant="h2" color={palette.common.white}>
              The hottest style for your team
            </Typography>
            <Typography component="p" color={palette.lightBackground.gray}>
              As an emerging name in the athletic wears industry, we know the importance of standing out. That is why we
              make it a priority to give each of our teams the opportunity to utilize our design team for custom designs
              with no additional fees. Whether you have your own existing design or you want to collaborate with us, we
              are very confident that you will have a look that is all your own.
            </Typography>
            <Typography component="p" color={palette.lightBackground.gray}>
              We understand quality is priority one with product delivery a close second. That said, we'll contact you
              within 24 hrs of your request and will do everything we can to ensure your timelines are met. Send us your
              request today and let's get your school and or organization wearing the newest and hottest emerging brand
              in the market! We look forward to hearing from you!
            </Typography>
            <Typography component="p" color={palette.lightBackground.gray}>
              David, owner/founder
            </Typography>
            <Button component={Link} to="/start" size="medium" variant="contained">
              start now
            </Button>
          </HomeInner>
        </HomeContent>
      </Stack>
    </>
  );
};

export default Home;
