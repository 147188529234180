import { styled, Stack } from '@mui/material';

export const CheckoutCard = styled(Stack)(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.common.white,
  padding: spacing(3),
  borderRadius: spacing(2),
  border: `1px solid ${palette.lightBackground.gray}`,
}));
export const CheckoutImageThumb = styled(Stack)(({ theme: { palette, spacing } }) => ({
  width: spacing(14),
  height: spacing(14),
  borderRadius: 12,
  overflow: 'hidden',
  padding: spacing(),
  border: `1px solid ${palette.lightBackground.gray}`,
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));
