import { Stack, styled } from '@mui/material';

export const StyledLoadedImage = styled(Stack)(({ theme: { palette, spacing } }) => ({
  padding: spacing(),
  border: `1px solid ${palette.grey[300]}`,
  borderRadius: 8,
}));

export const LoadedImageThumb = styled(Stack)(({ theme: { palette, spacing } }) => ({
  width: spacing(8),
  height: spacing(8),
  backgroundColor: palette.grey[100],
  borderRadius: 4,
  overflow: 'hidden',
  padding: spacing(),
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));
