import { FC, ReactElement, useContext, useState } from 'react';
import { Stack, Typography, Stepper, Step, StepLabel, TextField, MenuItem, Button } from '@mui/material';
import { UniformContext } from 'context/uniformContext';
import { usStates } from 'utils';
import { CheckoutCard } from 'routes/checkout/checkout.styles';
import { CheckoutResponse } from '../../checkout-components';
import { CustomerInfo, OrderInfo } from 'context/reducers/uniformReducer';

const initiaBasiclValues = { name: '', email: '', organization: '', state: 'Alabama', city: '', phone: '' };
const initiaUniformValues = { quantity: 0, additionalInfo: '' };

const BasicInformation = ({ changeActiveStep, setData }: any) => {
  const [form, setForm] = useState(initiaBasiclValues);

  const handleUsStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      state: event.target.value,
    }));
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmitForm = () => {
    setData(form);
    changeActiveStep(1);
  };

  return (
    <Stack spacing={3}>
      <TextField
        id="name"
        label="Name"
        name="name"
        variant="outlined"
        value={form.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
      />
      <TextField
        id="email"
        label="Email"
        name="email"
        variant="outlined"
        type="email"
        value={form.email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
      />
      <TextField
        id="organization"
        label="Organization"
        name="organization"
        variant="outlined"
        value={form.organization}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
      />
      <Stack direction="row" spacing={3}>
        <TextField
          id="state"
          label="State"
          name="state"
          variant="outlined"
          sx={{ flex: '1 1' }}
          select
          value={form.state}
          onChange={handleUsStateChange}
        >
          {usStates.map((option: string) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="city"
          label="City"
          name="city"
          variant="outlined"
          sx={{ flex: '1 1' }}
          value={form.city}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
      </Stack>
      <TextField
        id="phone"
        label="Phone Number"
        name="phone"
        variant="outlined"
        type="tel"
        value={form.phone}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
      />
      <Stack direction="row" justifyContent="flex-end">
        <Button size="large" variant="contained" onClick={onSubmitForm}>
          Next
        </Button>
      </Stack>
    </Stack>
  );
};

const UniformsInformation = ({ changeActiveStep, setData }: any) => {
  const [form, setForm] = useState(initiaUniformValues);

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Stack spacing={3}>
      <TextField
        id="uniforms_quantity"
        label="Quantity"
        name="quantity"
        type="number"
        variant="outlined"
        value={form.quantity}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
      />
      <TextField
        id="additionalInfo"
        label="Additional Information"
        name="additionalInfo"
        variant="outlined"
        multiline
        rows={5}
        value={form.additionalInfo}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
      />
      <Stack direction="row" justifyContent="space-between">
        <Button size="large" variant="text" onClick={() => changeActiveStep(0)}>
          Back
        </Button>
        <Button size="large" variant="contained" onClick={() => setData(form)}>
          Submit
        </Button>
      </Stack>
    </Stack>
  );
};

const CheckoutForm: FC = (): ReactElement => {
  const { setQuotation, state } = useContext(UniformContext);
  const [activeStep, setActiveStep] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [basicInfo, setBasicInfo] = useState<CustomerInfo>(initiaBasiclValues);

  const handleClickOpen = (orderInfo: OrderInfo) => {
    setQuotation(basicInfo, orderInfo, state, () => {});
    setDialogOpen(true);
  };

  return (
    <>
      <CheckoutCard>
        <Stack spacing={4}>
          <Typography align="center" variant="h2" fontSize="2rem">
            Complete your order
          </Typography>
          <Stepper activeStep={activeStep} sx={{ padding: '0 32px' }}>
            <Step>
              <StepLabel>Basic Information</StepLabel>
            </Step>
            <Step>
              <StepLabel>Uniforms Information</StepLabel>
            </Step>
          </Stepper>
          {activeStep === 0 ? (
            <BasicInformation changeActiveStep={setActiveStep} setData={(data: any) => setBasicInfo(data)} />
          ) : (
            <UniformsInformation setData={(data: any) => handleClickOpen(data)} />
          )}
        </Stack>
      </CheckoutCard>
      <CheckoutResponse open={dialogOpen} setClosed={() => setDialogOpen(false)} />
    </>
  );
};

export default CheckoutForm;
