// eslint-disable-next-line no-restricted-imports
import type { TypographyOptions } from '@mui/material/styles/createTypography';
import { rem } from 'polished';

import basePalette from './base-palette';

const baseTypography: TypographyOptions = {
  fontFamily: 'lato, sans-serif',
  fontSize: 16,
  h1: {
    fontSize: rem('64px'),
    fontWeight: 700,
    lineHeight: 1.1,
    fontFamily: 'oswald, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
    '@media (max-width:900px)': {
      fontSize: rem('48px'),
    },
  },
  h2: {
    fontSize: rem('48px'),
    fontWeight: 700,
    lineHeight: 1.1,
    fontFamily: 'oswald, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
    '@media (max-width:900px)': {
      fontSize: rem('38px'),
    },
  },
  h3: {
    fontSize: rem('40px'),
    fontWeight: 500,
    lineHeight: rem('48px'),
  },
  h4: {
    fontSize: rem('32px'),
    fontWeight: 700,
    lineHeight: 1.1,
    fontFamily: 'oswald, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
  },
  h5: {
    fontSize: rem('28px'),
    fontWeight: 500,
    lineHeight: rem('32px'),
  },
  h6: {
    fontSize: rem('24px'),
    fontWeight: 500,
    lineHeight: rem('28px'),
  },
  subtitle1: {
    fontSize: rem('18px'),
    lineHeight: rem('24px'),
  },
  body1: {
    fontSize: rem('16px'),
    lineHeight: 1.4,
  },
  body2: {
    fontSize: rem('12px'),
    lineHeight: 1,
  },
  button: {
    textTransform: 'none',
    fontWeight: 400,
  },
  overline: {
    color: basePalette.grey?.[700],
    fontSize: rem('16px'),
    fontWeight: 500,
    textTransform: 'none',
  },
};

export default baseTypography;
