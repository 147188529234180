import { FC, ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Grid, Stack, Typography, SvgIcon, Divider } from '@mui/material';
import { palette } from 'theme';
import { UniformContext } from '../../context/uniformContext';
import { Header } from 'components';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';

import { CheckoutImageThumb } from './checkout.styles';
import { CheckoutForm } from './checkout-components';

const Checkout: FC = (): ReactElement => {
  const { state: uniformState } = useContext(UniformContext);

  const pantsName = uniformState.uniformType === 'Women' ? 'Shorts' : 'Pants';
  return (
    <>
      <Header>
        <Button component={Link} to="/" size="small" sx={{ color: '#000000' }}>
          Go to Home
        </Button>
      </Header>
      <Container sx={{ mt: 8, mb: 8 }}>
        <Grid container spacing={6}>
          <Grid item sm={6}>
            <Stack spacing={3}>
              <Typography variant="h2">We're almost Ready</Typography>
              <Typography variant="body1" color={palette.grey[500]}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet risus at blandit posuere. Praesent
                at quam vel tortor porttitor dictum id et velit.
              </Typography>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 1 }}>
                  <CheckoutImageThumb>
                    <img src={uniformState.shirt.screenShot} alt={`${uniformState.uniformType} shirt`} />
                  </CheckoutImageThumb>
                  <Stack spacing={1.5}>
                    <Typography
                      fontWeight="bold"
                      variant="h6"
                      fontSize={'1.25em'}
                      sx={{ textTransform: 'uppercase', letterSpacing: '0.16em' }}
                    >
                      {uniformState.uniformType}'s Shirt
                    </Typography>
                    <Stack spacing={0.25}>
                      <Typography variant="body1" color={palette.grey[500]}>
                        Primary Color: R:{uniformState.shirt.primaryColor.r}, G:{uniformState.shirt.primaryColor.g}, B:{' '}
                        {uniformState.shirt.primaryColor.b}
                      </Typography>
                      <Typography variant="body1" color={palette.grey[500]}>
                        Secondary Color: R:{uniformState.shirt.secondaryColor.r}, G:
                        {uniformState.shirt.secondaryColor.g}, B: {uniformState.shirt.secondaryColor.b}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Divider />
                {!uniformState.noPants && (
                  <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 1 }}>
                    <CheckoutImageThumb>
                      <img src={uniformState.pants.screenShot} alt={`${uniformState.uniformType} pants`} />
                    </CheckoutImageThumb>
                    <Stack spacing={1.5}>
                      <Typography
                        fontWeight="bold"
                        variant="h6"
                        fontSize={'1.25em'}
                        sx={{ textTransform: 'uppercase', letterSpacing: '0.16em' }}
                      >
                        {uniformState.uniformType}'s {pantsName}
                      </Typography>
                      <Stack spacing={0.25}>
                        <Typography variant="body1" color={palette.grey[500]}>
                          Primary Color: R:{uniformState.pants.primaryColor.r}, G:{uniformState.pants.primaryColor.g},
                          B: {uniformState.pants.primaryColor.b}
                        </Typography>
                        <Typography variant="body1" color={palette.grey[500]}>
                          Secondary Color: R:{uniformState.pants.secondaryColor.r}, G:
                          {uniformState.pants.secondaryColor.g}, B: {uniformState.pants.secondaryColor.b}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                )}

                <Stack direction="row">
                  <Button
                    to={uniformState.uniformType === 'Men' ? '/builder/football-men' : '/builder/football-women'}
                    component={Link}
                    size="small"
                    variant="text"
                    className="builder-header-back"
                    sx={{ color: palette.common.black }}
                    startIcon={
                      <SvgIcon>
                        <ArrowLeft />
                      </SvgIcon>
                    }
                  >
                    Back to Edit
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item sm={6}>
            <CheckoutForm />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Checkout;
