import { ReactNode, Suspense, useState, useRef, ReactElement, FC, useContext } from 'react';
import { CircularProgress, Stack, useMediaQuery } from '@mui/material';
import type { RgbColor } from 'react-colorful';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, Environment } from '@react-three/drei';
import { UniformContext } from '../../../../context/uniformContext';
import { BuilderHeader, Sidebar } from '../index';
import { UniformParts } from 'context/reducers/uniformReducer';

import { StyledLoader } from './builder-layout.styles';
import theme from 'theme';

type BuilderLayoutProps = {
  children: ReactNode;
  primaryColor: RgbColor;
  secondaryColor: RgbColor;
  onChangePrimary: (value: RgbColor) => void;
  onChangeSecondary: (value: RgbColor) => void;
  isEditing: UniformParts;
  onChangeEditing: () => void;
  hasSecondaryColor: boolean;
  headerTitle: string;
  customHeaderSubtitle?: string;
};

type EsceneProps = {
  children: ReactNode;
  myRef: any;
  setScreenshot: Function;
};

const Loader = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: `calc(100vh - ${isMobile ? '250px' : '150px'})` }}>
      <CircularProgress />
    </Stack>
  );
};

const Escene: FC<EsceneProps> = ({ children, myRef, setScreenshot }: EsceneProps): ReactElement => {
  const { gl, scene, camera } = useThree();
  const [imageUrl, setImageUrl] = useState('');

  const handleClick = () => {
    gl.render(scene, camera);
    const screenshot = gl.domElement.toDataURL('image/png');
    setImageUrl(screenshot);
    setScreenshot(screenshot);
  };

  myRef.current = {
    handleClick: handleClick,
    imageUrl: imageUrl,
  };

  return (
    <>
      {children}
      <Environment preset="city" />
    </>
  );
};

const BuilderLayout = ({
  children,
  primaryColor,
  secondaryColor,
  onChangePrimary,
  onChangeSecondary,
  isEditing,
  onChangeEditing,
  hasSecondaryColor,
  headerTitle,
  customHeaderSubtitle,
  ...props
}: BuilderLayoutProps) => {
  const { setScreenshot } = useContext(UniformContext);

  const [layoutPrimaryColor, setLayoutPrimaryColor] = useState(primaryColor);
  const [layoutSecondaryColor, setLayoutSecondaryColor] = useState(secondaryColor);
  const [screenShotUrl, setScreenshotUrl] = useState('');
  const orbitRef = useRef<any>(null);
  const canvasRef = useRef(null);
  const buttonRef = useRef<any>({});
  const [loaderOpen, setLoaderOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const headerSubtitle = customHeaderSubtitle || isEditing;

  const handleChangePrimary = (value: RgbColor) => {
    setLayoutPrimaryColor(value);
    onChangePrimary(value);
  };

  const handleChangeSecondary = (value: RgbColor) => {
    setLayoutSecondaryColor(value);
    onChangeSecondary(value);
  };

  const handleResetOrbit = () => {
    setLoaderOpen(true);
    setTimeout(() => {
      handleGenerateScreenshot();
      onChangeEditing();
      setLoaderOpen(false);
    }, 1500);
    if (orbitRef.current !== null) {
      orbitRef.current.reset();
    }
  };

  const handleGenerateScreenshot = () => {
    buttonRef.current.handleClick();
    setScreenshotUrl(buttonRef.current.imageUrl);
  };

  return (
    <Stack direction="row" {...props}>
      <div style={{ flex: '1 1', position: 'relative' }}>
        <BuilderHeader headerTitle={headerTitle} headerSubtitle={`Customize ${headerSubtitle}`} />
        <Suspense fallback={<Loader />}>
          <Canvas
            id="model_canvas"
            style={{ height: `calc(100vh - ${isMobile ? '250px' : '150px'})`, width: '100%' }}
            shadows
            ref={canvasRef}
            gl={{ preserveDrawingBuffer: true }}
            camera={{ position: [0, 1.5, 3.5] }}
          >
            <ambientLight intensity={0.3} />
            <spotLight intensity={0.2} angle={0.1} penumbra={1} position={[10, 10, 10]} castShadow />
            <Escene
              myRef={buttonRef}
              setScreenshot={(screenshot: string) => setScreenshot(screenshot, isEditing, () => {})}
            >
              {children}
            </Escene>
            <OrbitControls
              minPolarAngle={Math.PI / 4.5}
              maxPolarAngle={Math.PI / 1.3}
              enableZoom={true}
              enablePan={true}
              maxDistance={5}
              minDistance={2.5}
              ref={orbitRef}
            />
          </Canvas>
        </Suspense>
        {loaderOpen && (
          <StyledLoader alignItems="center" justifyContent="center">
            <CircularProgress />
          </StyledLoader>
        )}
      </div>
      <Sidebar
        primaryColor={layoutPrimaryColor}
        secondaryColor={layoutSecondaryColor}
        onChangePrimary={(color) => {
          handleChangePrimary(color);
        }}
        onChangeSecondary={(color) => {
          handleChangeSecondary(color);
        }}
        onChangeEditing={handleResetOrbit}
        isEditing={isEditing}
        hasSecondaryColor={hasSecondaryColor}
      />
    </Stack>
  );
};

BuilderLayout.defaultProps = {
  isEditing: 'Jersey',
  hasSecondaryColor: true,
};

export default BuilderLayout;
