import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  Button,
  Stack,
} from '@mui/material';
import theme, { palette } from 'theme';

type CheckoutResponseProps = {
  open: boolean;
  setClosed: () => void;
};

const CheckoutResponse = (props: CheckoutResponseProps) => {
  const { open, setClosed } = props;
  const [dialogOpen, setDialogOpen] = useState(open);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  return (
    <Dialog open={dialogOpen} onClose={setClosed} aria-labelledby="responsive-dialog-title">
      <DialogTitle component="div" id="responsive-dialog-title">
        <Typography align="center" variant="h2">
          WELCOME TO TEAM SANDVIPER
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center" color={palette.grey[500]}>
          Our team will contact you at the phone number you provided within 24 hours. We will also send an email to the
          address provided as a secondary contact if we don't reach you by phone. Speak soon!
        </DialogContentText>
      </DialogContent>
      <Stack direction={isMobile ? 'column' : 'row'} justifyContent="center" spacing={2} sx={{ p: 3 }}>
        <Button variant="contained" component={Link} to="/">
          Back to Home
        </Button>
        <Button variant="contained" href="https://sandviper.com" target="_blank">
          Go to Website
        </Button>
      </Stack>
    </Dialog>
  );
};

export default CheckoutResponse;
