import styled from '@emotion/styled';
import { Stack } from '@mui/material';

import bgIcon from 'assets/svg/bg-icon.svg';
import { palette, spacing } from 'theme';

export const HomeBg = styled('div')({
  width: '50%',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block',
  },
  '@media (max-width:900px)': {
    width: '100%',
    height: 200,
  },
});

export const HomeContent = styled(Stack)({
  backgroundColor: palette.common.black,
  width: '50%',
  backgroundImage: `url(${bgIcon})`,
  backgroundPosition: `calc(100% + 100px) center`,
  backgroundRepeat: 'no-repeat',
  overflow: 'auto',
  '@media (max-width:900px)': {
    width: '100%',
    display: 'block',
    overflow: 'visible',
  },
});

export const HomeInner = styled(Stack)({
  maxWidth: spacing(57),
  marginLeft: spacing(10),
  padding: spacing(6, 0),
  '@media (max-width:900px)': {
    padding: spacing(4, 3),
    display: 'block',
    marginLeft: 0,
  },
});
