import type { Components } from '@mui/material';

import { palette, spacing } from './base-theme';
// import robotoRegular from '../../assets/fonts/Roboto-Regular.ttf';
// import robotoMedium from '../../assets/fonts/Roboto-Medium.ttf';
// import msIcons from '../../assets/fonts/ms-icons/fonts/ms-icons.ttf';

const overrides: Components = {
  MuiCssBaseline: {
    styleOverrides: `
		body{
			background-color: #F5F7FF;
		};
    `,
  },
  MuiAppBar: {
    styleOverrides: {
      positionStatic: {
        justifyContent: 'center',
        backgroundColor: 'transparent',
      },
    },
  },
  /**
   * @todo look for the icon component and the library to use the font as 20
   * but the size as 24, so this can be deletated
   * @author ms-joaquin-ca
   */
  MuiIcon: {
    styleOverrides: {
      root: {
        fontSize: 24,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: 12,
        borderRadius: spacing(1),
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'uppercase',
        letterSpacing: '0.16em',
        lineHeight: 1,
        fontWeight: 700,
      },
      sizeMedium: {
        fontSize: 12,
        padding: spacing(2, 4),
      },
      sizeLarge: {
        fontSize: 14,
        padding: spacing(2.5, 4),
      },
      outlined: {
        ':hover': {
          backgroundColor: palette.primary.dark,
          borderColor: palette.primary.dark,
          color: palette.common.white,
        },
      },
    },
    defaultProps: {
      disableElevation: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: palette.lightBackground.gray,
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      line: {
        borderColor: palette.lightBackground.gray,
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      labelContainer: {
        color: palette.lightBackground.gray,
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: palette.lightBackground.gray,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        padding: 16,
        borderRadius: 12,
      },
    },
  },
};

export default overrides;
