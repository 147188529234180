import * as THREE from 'three';
import { useRef, useEffect, useState, FC } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

import type { RgbColor } from 'react-colorful/dist/types';
import { ModelProps } from 'types/models/model';
import { useLoader } from '@react-three/fiber';

type GLTFResult = GLTF & {
  nodes: {
    Pantalon_hombre_low: THREE.Mesh;
    Dinamico: THREE.Mesh;
    Escudo: THREE.Mesh;
  };
  materials: {
    Pantalon: THREE.MeshStandardMaterial;
    Dinamico: THREE.MeshStandardMaterial;
    Escudo: THREE.MeshStandardMaterial;
  };
};

const getBrightness = ({ r, g, b }: RgbColor) => (r * 299 + g * 587 + b * 114) / 1000;

const PantstMen: FC<ModelProps> = ({ primaryColor, secondaryColor, logo, ...props }: ModelProps) => {
  const [color, setColor] = useState<RgbColor>(primaryColor);
  const group = useRef(null);

  const { nodes, materials } = useGLTF('/models/pantsMenDraco2.glb') as GLTFResult;
  const skinMap = useLoader(TextureLoader, '/fabric_skin_2.png');
  const fabricMap = useLoader(TextureLoader, '/fabric_roughness.png');
  const svp_logo = useLoader(TextureLoader, '/icon-512.png');
  const svp_logo_w = useLoader(TextureLoader, '/icon-512-w.png');
  const client_logo = useLoader(TextureLoader, logo);

  const contrastLogo = getBrightness(color) > 128 ? svp_logo : svp_logo_w;
  const primaryColorString = `rgb(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b})`;
  //const secondaryColorString = `rgb(${secondaryColor.r}, ${secondaryColor.g}, ${secondaryColor.b})`;

  useEffect(() => {
    setColor(primaryColor);
  }, [primaryColor]);

  client_logo.flipY = false;
  contrastLogo.flipY = false;

  //skinMap.magFilter = NearestFilter;
  //skinMap.minFilter = LinearMipMapLinearFilter;
  skinMap.repeat.set(9, 9);
  skinMap.wrapS = THREE.RepeatWrapping;
  skinMap.wrapT = THREE.RepeatWrapping;

  fabricMap.repeat.set(6, 6);
  fabricMap.wrapS = THREE.RepeatWrapping;
  fabricMap.wrapT = THREE.RepeatWrapping;

  return (
    <group ref={group} {...props} dispose={null} scale={0.04}>
      <mesh
        geometry={nodes.Pantalon_hombre_low.geometry}
        material={materials.Pantalon}
        position={[0, 46.32, 0]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <meshStandardMaterial color={primaryColorString} roughnessMap={skinMap} map={skinMap} roughness={1.2} />
      </mesh>
      <mesh
        geometry={nodes.Dinamico.geometry}
        material={materials.Dinamico}
        position={[-17.25, 32.15, 14.99]}
        rotation={[1.57, -0.08, 0.57]}
        scale={4.26}
      >
        <meshPhysicalMaterial
          map={contrastLogo}
          toneMapped={false}
          transparent
          reflectivity={0}
          metalness={1}
          roughness={2}
        />
      </mesh>
      <mesh
        geometry={nodes.Escudo.geometry}
        material={materials.Escudo}
        position={[18.58, 32.17, 13.84]}
        rotation={[1.6, 0.03, -0.61]}
        scale={4.61}
      >
        <meshPhysicalMaterial
          map={client_logo}
          toneMapped={false}
          transparent
          reflectivity={0}
          metalness={1}
          roughness={2}
        />
      </mesh>
    </group>
  );
};

useGLTF.preload('/models/pantsMenDraco2.glb');

export default PantstMen;
