import axios from 'axios';
import { RgbColor } from 'react-colorful';
import {
  CustomerInfo,
  OrderInfo,
  UniformParts,
  UniformpieceType,
  UniformsContextType,
  UniformTypes,
} from 'context/reducers/uniformReducer';
import UniformActionsTypes from './types/uniform';

export const setResetState = (dispatch: any) => {
  return async (callback: () => void) => {
    dispatch({ type: UniformActionsTypes.SET_UNIFORM_RESET_STATE });
    callback();
  };
};

export const setColor = (dispatch: any) => {
  return async (isPrimary: boolean, currentPartInEdition: UniformParts, color: RgbColor, callback: () => void) => {
    if (currentPartInEdition === 'Jersey')
      dispatch({ type: UniformActionsTypes.SET_UNIFORM_SHIRT_COLOR, payload: { isPrimary, color } });
    else dispatch({ type: UniformActionsTypes.SET_UNIFORM_PANTS_COLOR, payload: { isPrimary, color } });

    callback();
  };
};

export const setLogoInfo = (dispatch: any) => {
  return async (base64: string | ArrayBuffer | null, filename: string, callback: () => void) => {
    dispatch({ type: UniformActionsTypes.SET_UNIFORM_LOGO, payload: { base64, filename } });

    callback();
  };
};

export const setUniformType = (dispatch: any) => {
  return async (gender: UniformTypes, callback: () => void) => {
    dispatch({ type: UniformActionsTypes.SET_UNIFORM_TYPE, payload: gender });
    callback();
  };
};

export const setScreenshot = (dispatch: any) => {
  return async (screenShot: string, currentPartInEdition: UniformParts, callback: () => void) => {
    if (currentPartInEdition === 'Jersey')
      dispatch({ type: UniformActionsTypes.SET_UNIFORM_SHIRT_SCREENSHOT, payload: screenShot });
    else dispatch({ type: UniformActionsTypes.SET_UNIFORM_PANTS_SCREENSHOT, payload: screenShot });

    callback();
  };
};

export const setPartInEdition = (dispatch: any) => {
  return async (part: UniformParts, callback: () => void) => {
    dispatch({ type: UniformActionsTypes.SET_UNIFORM_PART_EDITING, payload: part });
    callback();
  };
};

export const setShirtInfo = (dispatch: any) => {
  return async (shirt: UniformpieceType, callback: () => void) => {
    dispatch({ type: UniformActionsTypes.SET_UNIFORM_SHIRT_INFO, payload: shirt });
    callback();
  };
};

export const setPantsInfo = (dispatch: any) => {
  return async (pants: UniformpieceType, callback: () => void) => {
    dispatch({ type: UniformActionsTypes.SET_UNIFORM_PANTS_INFO, payload: pants });
    callback();
  };
};

export const setNoPants = (dispatch: any) => {
  return async (noPants: boolean, callback: () => void) => {
    dispatch({ type: UniformActionsTypes.SET_UNIFORM_NO_PANTS, payload: noPants });
    callback();
  };
};

export const setQuotation = () => {
  return async (customer: CustomerInfo, order: OrderInfo, state: UniformsContextType, callback: () => void) => {
    const { REACT_APP_QUOTATION_API } = process.env;
    const quotation: any = {
      customer,
      order,
      uniform: {
        gender: state.uniformType,
        logo: {
          base64Image: state.logoInfo.base64,
        },
        tshirt: {
          primaryColor: state.shirt.primaryColor,
          secondaryColor: state.shirt.secondaryColor,
          base64Image: state.shirt.screenShot,
        },
        pants: {
          primaryColor: state.pants.primaryColor,
          secondaryColor: state.pants.secondaryColor,
          base64Image: state.pants.screenShot,
        },
      },
    };
    if (state.noPants) delete quotation.uniform.pants;

    const response = await axios.post(`${REACT_APP_QUOTATION_API}/quotation`, quotation);
    console.log(response);
    callback();
  };
};
