import { FC, ReactElement, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import theme, { palette, spacing } from 'theme';
import { UniformTypes } from 'context/reducers/uniformReducer';
import { UniformContext } from 'context/uniformContext';
import { Header } from 'components';
import uniformMen from 'assets/images/uniform-men.jpg';
import uniformWomen from 'assets/images/uniform-women.png';
import { StartContainer, UniformCard } from './start.styles';

const Start: FC = (): ReactElement => {
  const navigate = useNavigate();

  const { setUniformType, setResetState } = useContext(UniformContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setResetState(() => {});
  }, []);

  const handleUniformTypeClick = (gender: UniformTypes, path: string) => {
    setUniformType(gender, () => {
      navigate(path);
    });
  };

  return (
    <>
      <Header />
      <StartContainer alignItems="center" justifyContent={!isMobile ? `center` : `flex-start`}>
        <Stack spacing={3} sx={{ maxWidth: spacing(80) }}>
          <Typography align="center" variant="h1">
            Let's Start
          </Typography>
          <Typography variant="body1" align="center" color={palette.grey[500]}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed laoreet risus at blandit posuere. Praesent at
            quam vel tortor porttitor dictum id et velit.
          </Typography>
          <Stack direction="row" justifyContent="center" spacing={isMobile ? 2 : 4}>
            <UniformCard onClick={() => handleUniformTypeClick('Men', '/builder/football-men')}>
              <img src={uniformMen} alt="men" />
              <Typography variant="h4">For Men</Typography>
            </UniformCard>
            <UniformCard onClick={() => handleUniformTypeClick('Women', '/builder/football-women')}>
              <img src={uniformWomen} alt="Women" />
              <Typography variant="h4">For Women</Typography>
            </UniformCard>
          </Stack>
        </Stack>
      </StartContainer>
    </>
  );
};

export default Start;
