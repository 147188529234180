/* eslint-disable import/no-anonymous-default-export */
import { Reducer, Dispatch, useReducer, createContext } from 'react';

export interface ActionInterface {
  type: string;
  payload?: any;
}

type BoundActions<A, T> = {
  [K in keyof T]: T[K] extends (d: Dispatch<A>) => infer R ? R : never;
};

type ContextValue<S, T, A> = {
  state: S;
} & BoundActions<A, T>;

type ProviderProps = {
  children: React.ReactNode;
};

export default function <S, A extends ActionInterface, T extends {}>(
  reducer: Reducer<S, A>,
  actions: T,
  initialState: S
) {
  const Context = createContext({
    state: initialState,
  } as ContextValue<S, T, A>);

  const Provider = ({ children }: ProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const boundActions = {} as BoundActions<A, T>;

    for (const key in actions) {
      // @ts-ignore
      boundActions[key] = actions[key](dispatch);
    }

    return <Context.Provider value={{ state, ...boundActions }}>{children}</Context.Provider>;
  };

  return { Context, Provider };
}
