import { Stack, styled } from '@mui/material';

import bgIcon from 'assets/svg/bg-icon.svg';
import { palette, spacing } from 'theme';

export const StartContainer = styled(Stack)({
  height: `calc(100vh - 80px)`,
  backgroundImage: `url(${bgIcon})`,
  backgroundPosition: `calc(100% + 100px) center`,
  backgroundRepeat: 'no-repeat',
  padding: spacing(3),
});

export const UniformCard = styled('div')({
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden',
  '&:hover': {
    img: {
      transform: 'scale(1.1,1.1)',
    },
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.5)',
    zIndex: 2,
  },
  '.MuiTypography-root': {
    color: palette.common.white,
    position: 'absolute',
    bottom: 50,
    zIndex: 3,
    paddingLeft: 48,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 40,
      height: 1,
      background: palette.common.white,
      left: 0,
      top: '50%',
    },
  },
  img: {
    display: 'block',
    transition: 'all 0.3s',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  '@media (max-width:900px)': {
    height: 200,
    '.MuiTypography-root': {
      bottom: 20,
      paddingLeft: 40,
      '&::before': {
        width: 20,
      },
    },
  },
});
