import { createTheme, ThemeOptions } from '@mui/material';

import basePalette from './base-palette';
import baseTypography from './base-typography';

const themeOptions: ThemeOptions = {
  palette: basePalette,
  typography: baseTypography,
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
};

const baseTheme = createTheme(themeOptions);

export const { palette, typography, spacing, shape } = baseTheme;

export default baseTheme;
