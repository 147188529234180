import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import type { RgbColor } from 'react-colorful';
import { UniformContext } from 'context/uniformContext';
import { ColorPicker, LoadedImage } from 'components';
import { base64ToBlob } from 'utils';
import { StyledDrawer, HiddenInput, MobileButtonsContainer, StyledEditButton } from './sidebar.styles';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { ReactComponent as EditIcon } from 'assets/svg/edit-3.svg';

import theme from 'theme';

type SidebarProps = {
  primaryColor: RgbColor;
  secondaryColor: RgbColor;
  onChangePrimary: (value: RgbColor) => void;
  onChangeSecondary: (value: RgbColor) => void;
  onChangeEditing: () => void;
  isEditing: string;
  hasSecondaryColor: boolean;
};

const Sidebar = ({
  primaryColor,
  secondaryColor,
  onChangePrimary,
  onChangeSecondary,
  onChangeEditing,
  isEditing,
  hasSecondaryColor,
  ...props
}: SidebarProps) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { setLogoInfo, setNoPants, state: uniformState } = useContext(UniformContext);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(isMobile ? false : true);

  const handleChangePrimary = (value: RgbColor) => {
    onChangePrimary(value);
  };

  const handleChangeSecondary = (value: RgbColor) => {
    onChangeSecondary(value);
  };

  const handleFinishEditing = () => {
    onChangeEditing();
    setNoPants(true, () => {});
    setTimeout(() => {
      navigate('/checkout');
    }, 1500);
  };

  const convertBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileClick = () => {
    inputRef.current?.click();
  };

  const removeSelectedFile = () => {
    setSelectedFile(undefined);
    setPreview('');
  };

  useEffect(() => {
    if (uniformState.logoInfo.base64 !== '') {
      const objectUrl = base64ToBlob(uniformState.logoInfo.base64 as string);
      setPreview(objectUrl);
    }
  }, [uniformState.logoInfo.base64]);

  useEffect(() => {
    if (!selectedFile) {
      setLogoInfo('', '', () => {});
      return;
    }

    const fetchData = async () => {
      const base64 = await convertBase64(selectedFile);
      setLogoInfo(base64, selectedFile.name, () => {});
    };

    fetchData().catch(console.error);
  }, [selectedFile]);

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      <StyledDrawer
        open={isDrawerOpen}
        variant={isMobile ? 'temporary' : 'permanent'}
        anchor={isMobile ? `bottom` : `right`}
        onClose={() => setIsDrawerOpen(false)}
        {...props}
      >
        <Stack justifyContent="space-between" sx={{ height: '100%' }}>
          <Stack sx={{ pt: 4 }} spacing={6} justifyContent="space-between">
            <Stack spacing={3}>
              {isEditing === 'Pants' && (
                <Stack alignItems="flex-start">
                  <Button
                    size="small"
                    variant="text"
                    onClick={onChangeEditing}
                    startIcon={
                      <SvgIcon>
                        <ArrowLeft />
                      </SvgIcon>
                    }
                  >
                    Go back to edit Shirt
                  </Button>
                </Stack>
              )}
              <Typography variant="h4" sx={{ fontSize: 24 }}>
                Customize colors
              </Typography>
              <Stack spacing={2}>
                <ColorPicker
                  label="Primary color"
                  onChange={(color) => handleChangePrimary(color)}
                  color={
                    uniformState.partInEdition === 'Jersey'
                      ? uniformState.shirt.primaryColor
                      : uniformState.pants.primaryColor
                  }
                />
                {hasSecondaryColor && (
                  <ColorPicker
                    label="Secondary color"
                    onChange={(color) => handleChangeSecondary(color)}
                    color={
                      uniformState.partInEdition === 'Jersey'
                        ? uniformState.shirt.secondaryColor
                        : uniformState.pants.secondaryColor
                    }
                  />
                )}
              </Stack>
            </Stack>
            <Stack spacing={3}>
              <Typography variant="h4" sx={{ fontSize: 24 }}>
                Add Your Logo
              </Typography>
              <Stack spacing={1}>
                <HiddenInput
                  type="file"
                  ref={inputRef}
                  size={60}
                  onChange={onSelectFile}
                  accept="image/png, image/gif, image/jpeg"
                />
                <Button variant="outlined" onClick={handleFileClick}>
                  Upload Logo
                </Button>
                {preview !== '' && (
                  <LoadedImage
                    imageSrc={preview || ''}
                    imageName={uniformState.logoInfo.filename || ''}
                    buttonAction={removeSelectedFile}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          {!isMobile && (
            <Stack spacing={1}>
              {isEditing === 'Jersey' ? (
                <Button className="absolute-button" variant="contained" size="large" onClick={onChangeEditing}>
                  Next
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleFinishEditing();
                      setNoPants(true, () => {});
                    }}
                  >
                    Skip Pants and finish editing
                  </Button>
                  <Button
                    className="absolute-button"
                    onClick={() => {
                      handleFinishEditing();
                      setNoPants(false, () => {});
                    }}
                    variant="contained"
                  >
                    Finish editing
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Stack>
      </StyledDrawer>

      {isMobile && (
        <MobileButtonsContainer spacing={1}>
          <Stack
            direction="row"
            justifyContent={isEditing === 'Jersey' ? 'flex-end' : 'space-between'}
            alignItems="center"
          >
            {isEditing === 'Pants' && (
              <Stack alignItems="flex-start">
                <Button
                  size="small"
                  variant="text"
                  onClick={onChangeEditing}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <ArrowLeft />
                    </SvgIcon>
                  }
                >
                  Go back to edit Shirt
                </Button>
              </Stack>
            )}
            <StyledEditButton onClick={() => setIsDrawerOpen(true)} variant="outlined" size="small">
              <EditIcon />
            </StyledEditButton>
          </Stack>
          <Stack direction="row" spacing={1}>
            {isEditing === 'Jersey' ? (
              <Button
                fullWidth
                className="absolute-button"
                variant="contained"
                size="medium"
                onClick={onChangeEditing}
                sx={{ fontSize: '10px' }}
              >
                Next
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleFinishEditing();
                    setNoPants(true, () => {});
                  }}
                >
                  Skip Pants and finish
                </Button>
                <Button
                  onClick={() => {
                    handleFinishEditing();
                    setNoPants(false, () => {});
                  }}
                  variant="contained"
                  size="medium"
                >
                  Finish
                </Button>
              </>
            )}
          </Stack>
        </MobileButtonsContainer>
      )}
    </>
  );
};

Sidebar.defaultProps = {
  hasSecondaryColor: true,
};

export default Sidebar;
