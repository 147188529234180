import { ReactNode } from 'react';
import Logo from '../../assets/svg/logo.svg';
import { Link } from 'react-router-dom';

import { StyledHeader } from './header.styles';
import { Stack } from '@mui/material';

type HeaderProps = {
  children?: ReactNode;
};

const Header = (props: HeaderProps) => {
  const { children } = props;
  return (
    <StyledHeader position="static">
      <Stack justifyContent={children ? `space-between` : `center`} direction="row">
        <Link to="/">
          <img className="logo" src={Logo} alt="Logo" />
        </Link>
        {children}
      </Stack>
    </StyledHeader>
  );
};

export default Header;
