import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { UniformContext } from 'context/uniformContext';
import { ShirtWomen, PantsWomen } from 'components';
import { BuilderLayout } from '../builder-components';
import { base64ToBlob } from 'utils';

const FootballWomen: FC = (): ReactElement => {
  const { setColor, state: uniformState, setPartInEdition } = useContext(UniformContext);
  const [preview, setPreview] = useState('/transparent.png');

  useEffect(() => {
    if (uniformState.logoInfo.base64 !== '') {
      const objectUrl = base64ToBlob(uniformState.logoInfo.base64 as string);
      setPreview(objectUrl);
    }
  }, [uniformState.logoInfo.base64]);

  return (
    <>
      <BuilderLayout
        primaryColor={uniformState.shirt.primaryColor}
        secondaryColor={uniformState.shirt.secondaryColor}
        onChangePrimary={(color) => setColor(true, uniformState.partInEdition, color, () => {})}
        onChangeSecondary={(color) => setColor(false, uniformState.partInEdition, color, () => {})}
        isEditing={uniformState.partInEdition}
        onChangeEditing={() => setPartInEdition(uniformState.partInEdition === 'Jersey' ? 'Pants' : 'Jersey', () => {})}
        headerTitle="Women's Uniform"
        customHeaderSubtitle={uniformState.partInEdition === 'Pants' ? 'Shorts' : undefined}
      >
        {uniformState.partInEdition === 'Jersey' ? (
          <ShirtWomen
            primaryColor={uniformState.shirt.primaryColor}
            secondaryColor={uniformState.shirt.secondaryColor}
            logo={preview}
          />
        ) : (
          <PantsWomen
            primaryColor={uniformState.pants.primaryColor}
            secondaryColor={uniformState.pants.secondaryColor}
            logo={preview}
          />
        )}
      </BuilderLayout>
    </>
  );
};

export default FootballWomen;
