import { HashRouter, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from 'theme';
import { Provider as UniformProvider } from './context/uniformContext';
import { Home, Start, FootballMen, FootballWomen, Checkout } from 'routes';
import Storage from 'components/storage/Storage';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <UniformProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/start" element={<Start />} />
            <Route path="/builder/football-men" element={<FootballMen />} />
            <Route path="/builder/football-women" element={<FootballWomen />} />
            <Route path="/checkout" element={<Checkout />} />
          </Routes>
          <Storage />
        </UniformProvider>
      </HashRouter>
    </ThemeProvider>
  );
};

export default App;
