import { SvgIcon, Typography, IconButton } from '@mui/material';

import { StyledLoadedImage, LoadedImageThumb } from './loaded-image.styles';
import { ReactComponent as TrashIcon } from 'assets/svg/trash-2.svg';

type LoadedImageProps = {
  imageSrc: string;
  imageAlt?: string;
  imageName: string;
  buttonAction: () => void;
};

const LoadedImage = (props: LoadedImageProps) => {
  const { imageName, imageSrc, imageAlt, buttonAction } = props;

  return (
    <StyledLoadedImage direction="row" alignItems="center" spacing={1} justifyContent="space-between">
      <LoadedImageThumb>
        <img src={imageSrc} alt={imageAlt} />
      </LoadedImageThumb>
      <Typography variant="body2" sx={{ flex: '1 1', lineHeight: '1.4' }} noWrap>
        {imageName}
      </Typography>
      <IconButton color="primary" onClick={buttonAction}>
        <SvgIcon sx={{ fontSize: 20 }}>
          <TrashIcon />
        </SvgIcon>
      </IconButton>
    </StyledLoadedImage>
  );
};

export default LoadedImage;
