import { FC, useEffect, useRef, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { GLTF } from 'three-stdlib';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import type { RgbColor } from 'react-colorful/dist/types';
import { ModelProps } from 'types/models/model';

type GLTFResult = GLTF & {
  nodes: {
    Camiseta_mujer_low_1: THREE.Mesh;
    Camiseta_mujer_low_2: THREE.Mesh;
    Camiseta_mujer_low_3: THREE.Mesh;
    Camiseta_mujer_low_4: THREE.Mesh;
    Plane001: THREE.Mesh;
    Plane002: THREE.Mesh;
  };
  materials: {
    numero: THREE.MeshStandardMaterial;
    straps: THREE.MeshStandardMaterial;
    mangas: THREE.MeshStandardMaterial;
    tshirt_mujer: THREE.MeshStandardMaterial;
    dinamico: THREE.MeshStandardMaterial;
    escudo: THREE.MeshStandardMaterial;
  };
};

const getBrightness = ({ r, g, b }: RgbColor) => (r * 299 + g * 587 + b * 114) / 1000;

const ShirtWomen: FC<ModelProps> = ({ primaryColor, secondaryColor, logo, ...props }: ModelProps) => {
  const [color, setColor] = useState<RgbColor>(primaryColor);
  const group = useRef(null);

  const { nodes, materials } = useGLTF('/models/shirtWomen.glb') as GLTFResult;

  const skinMap = useLoader(TextureLoader, '/fabric_skin_2.png');
  const fabricMap = useLoader(TextureLoader, '/fabric_roughness.png');
  const svp_logo = useLoader(TextureLoader, '/icon-512.png');
  const svp_logo_w = useLoader(TextureLoader, '/icon-512-w.png');
  const client_logo = useLoader(TextureLoader, logo);

  const contrastLogo = getBrightness(color) > 128 ? svp_logo : svp_logo_w;
  const primaryColorString = `rgb(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b})`;
  const secondaryColorString = `rgb(${secondaryColor?.r}, ${secondaryColor?.g}, ${secondaryColor?.b})`;

  useEffect(() => {
    setColor(primaryColor);
  }, [primaryColor]);

  client_logo.flipY = false;

  contrastLogo.flipY = false;

  skinMap.repeat.set(10, 10);
  skinMap.wrapS = THREE.RepeatWrapping;
  skinMap.wrapT = THREE.RepeatWrapping;
  skinMap.flipY = false;

  fabricMap.repeat.set(6, 6);
  fabricMap.wrapS = THREE.RepeatWrapping;
  fabricMap.wrapT = THREE.RepeatWrapping;

  return (
    <group ref={group} {...props} dispose={null} scale={0.05}>
      <group rotation={[Math.PI / 2, 0, 0]} position={[0, -53.03, 0]}>
        <mesh geometry={nodes.Camiseta_mujer_low_2.geometry} material={materials.straps}>
          <meshStandardMaterial color={secondaryColorString} roughnessMap={fabricMap} roughness={2} />
        </mesh>
        <mesh geometry={nodes.Camiseta_mujer_low_3.geometry} material={materials.mangas}>
          <meshStandardMaterial color={secondaryColorString} roughnessMap={fabricMap} roughness={2} />
        </mesh>
        <mesh geometry={nodes.Camiseta_mujer_low_4.geometry} material={materials.tshirt_mujer}>
          <meshStandardMaterial color={primaryColorString} roughnessMap={skinMap} map={skinMap} roughness={1.2} />
        </mesh>
      </group>
      <mesh
        geometry={nodes.Plane001.geometry}
        material={materials.dinamico}
        position={[0, 25.97, 14.05]}
        rotation={[1.13, 0, 0]}
        scale={3.64}
      >
        <meshPhysicalMaterial
          map={client_logo}
          toneMapped={false}
          transparent
          reflectivity={0}
          metalness={1}
          roughness={2}
        />
      </mesh>
      <mesh
        geometry={nodes.Plane002.geometry}
        material={materials.escudo}
        position={[19.17, 32.55, 5.32]}
        rotation={[1.29, 0.12, -0.46]}
        scale={3.07}
      >
        <meshPhysicalMaterial
          map={contrastLogo}
          toneMapped={false}
          transparent
          reflectivity={0}
          metalness={1}
          roughness={2}
        />
      </mesh>
    </group>
  );
};

useGLTF.preload('/models/shirtWomen.glb');

export default ShirtWomen;
