// eslint-disable-next-line no-restricted-imports
import { createTheme } from '@mui/material/styles';

import { overrides, baseTheme } from './options';

const theme = createTheme(baseTheme, {
  components: overrides,
});

export default theme;
