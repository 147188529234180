enum UniformActionsTypes {
  SET_UNIFORM_TYPE = 'SET_UNIFORM_TYPE',
  SET_UNIFORM_PART_EDITING = 'SET_UNIFORM_PART_EDITING',
  SET_UNIFORM_RESET_STATE = 'SET_UNIFORM_RESET_STATE',
  SET_UNIFORM_SHIRT_COLOR = 'SET_UNIFORM_SHIRT_COLOR',
  SET_UNIFORM_PANTS_COLOR = 'SET_UNIFORM_PANTS_COLOR',
  SET_UNIFORM_SHIRT_SCREENSHOT = 'SET_UNIFORM_SHIRT_SCREENSHOT',
  SET_UNIFORM_PANTS_SCREENSHOT = 'SET_UNIFORM_PANTS_SCREENSHOT',
  SET_UNIFORM_SHIRT_INFO = 'SET_UNIFORM_SHIRT_INFO',
  SET_UNIFORM_PANTS_INFO = 'SET_UNIFORM_PANTS_INFO',
  SET_UNIFORM_LOGO = 'SET_UNIFORM_LOGO',
  SET_UNIFORM_NO_PANTS = 'SET_UNIFORM_NO_PANTS',
}

export default UniformActionsTypes;
