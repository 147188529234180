import { styled, Stack } from '@mui/material';

export const StyledLoader = styled(Stack)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255,255,255,0.9)',
  zIndex: 999,
  top: 0,
  left: 0,
});
