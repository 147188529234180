import { Button, Typography, SvgIcon, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { StyledBuilderHeader } from './builder-header.styles';

import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';

type BuilderHeaderProps = {
  backLink: string;
  headerTitle: string;
  headerSubtitle: string;
};

const BuilderHeader = ({ backLink, headerTitle, headerSubtitle, ...props }: BuilderHeaderProps) => {
  return (
    <StyledBuilderHeader>
      <Stack spacing={1} alignItems="flex-start">
        <Button
          to={backLink}
          component={Link}
          size="small"
          variant="text"
          className="builder-header-back"
          startIcon={
            <SvgIcon>
              <ArrowLeft />
            </SvgIcon>
          }
        >
          Back
        </Button>
        <Typography variant="h2">{headerTitle}</Typography>
        <Typography variant="body1" textTransform="uppercase" sx={{ letterSpacing: '0.16em' }}>
          {headerSubtitle}
        </Typography>
      </Stack>
    </StyledBuilderHeader>
  );
};

BuilderHeader.defaultProps = {
  backLink: '/start',
};

export default BuilderHeader;
