import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { GLTF } from 'three-stdlib';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

import type { RgbColor } from 'react-colorful/dist/types';

import { ModelProps } from 'types/models/model';

type GLTFResult = GLTF & {
  nodes: {
    Camiseta_hombre_1: THREE.Mesh;
    Camiseta_hombre_2: THREE.Mesh;
    Camiseta_hombre_3: THREE.Mesh;
    Camiseta_hombre_4: THREE.Mesh;
    Plane001: THREE.Mesh;
    Plane002: THREE.Mesh;
  };
  materials: {
    strap: THREE.MeshStandardMaterial;
    mangas: THREE.MeshStandardMaterial;
    tShirt: THREE.MeshStandardMaterial;
    number: THREE.MeshStandardMaterial;
    Escudo: THREE.MeshStandardMaterial;
    Dinamico: THREE.MeshStandardMaterial;
  };
};

const getBrightness = ({ r, g, b }: RgbColor) => (r * 299 + g * 587 + b * 114) / 1000;

const ShirtMen = ({ primaryColor, secondaryColor, logo, ...props }: ModelProps) => {
  const [color, setColor] = useState<RgbColor>(primaryColor);
  const group = useRef(null);

  const { nodes, materials } = useGLTF('/models/shirtMenDraco2.glb') as GLTFResult;

  const skinMap = useLoader(TextureLoader, '/fabric_skin_2.png');
  const fabricMap = useLoader(TextureLoader, '/fabric_roughness.png');
  const svp_logo = useLoader(TextureLoader, '/icon-512.png');
  const svp_logo_w = useLoader(TextureLoader, '/icon-512-w.png');
  const client_logo = useLoader(TextureLoader, logo);

  const contrastLogo = getBrightness(color) > 128 ? svp_logo : svp_logo_w;
  const primaryColorString = `rgb(${primaryColor.r}, ${primaryColor.g}, ${primaryColor.b})`;
  const secondaryColorString = `rgb(${secondaryColor?.r}, ${secondaryColor?.g}, ${secondaryColor?.b})`;

  useEffect(() => {
    setColor(primaryColor);
  }, [primaryColor]);

  client_logo.flipY = false;

  contrastLogo.flipY = false;

  //skinMap.magFilter = NearestFilter;
  //skinMap.minFilter = LinearMipMapLinearFilter;
  skinMap.repeat.set(10, 10);
  skinMap.wrapS = THREE.RepeatWrapping;
  skinMap.wrapT = THREE.RepeatWrapping;
  skinMap.flipY = false;

  fabricMap.repeat.set(6, 6);
  fabricMap.wrapS = THREE.RepeatWrapping;
  fabricMap.wrapT = THREE.RepeatWrapping;

  return (
    <group ref={group} {...props} dispose={null} scale={0.04}>
      <group position={[-0.53, -47.69, 3.48]} rotation={[Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes.Camiseta_hombre_1.geometry} material={materials.strap}>
          <meshStandardMaterial color={secondaryColorString} roughnessMap={fabricMap} roughness={2} />
        </mesh>
        <mesh geometry={nodes.Camiseta_hombre_2.geometry} material={materials.mangas}>
          <meshStandardMaterial color={secondaryColorString} roughnessMap={fabricMap} roughness={2} />
        </mesh>
        <mesh geometry={nodes.Camiseta_hombre_3.geometry}>
          <meshStandardMaterial color={primaryColorString} roughnessMap={skinMap} map={skinMap} roughness={1.2} />
        </mesh>
      </group>
      <mesh
        geometry={nodes.Plane001.geometry}
        material={materials.Escudo}
        position={[21.41, 38.1, 11.32]}
        rotation={[0.75, 0.03, -0.16]}
        scale={[4.4, 4.4, 4.4]}
      >
        <meshPhysicalMaterial
          map={contrastLogo}
          toneMapped={false}
          transparent
          reflectivity={0}
          metalness={1}
          roughness={2}
        />
      </mesh>
      <mesh
        geometry={nodes.Plane002.geometry}
        material={materials.Dinamico}
        position={[-0.36, 22.87, 23.02]}
        rotation={[1.26, 0, 0]}
        scale={4.86}
      >
        <meshPhysicalMaterial
          map={client_logo}
          toneMapped={false}
          transparent
          reflectivity={0}
          metalness={1}
          roughness={2}
        />
      </mesh>
    </group>
  );
};

useGLTF.preload('/models/shirtMenDraco2.glb');

export default ShirtMen;
