/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactElement, useContext, useEffect } from 'react';
import { initialState } from 'context/reducers/uniformReducer';
import { UniformContext } from 'context/uniformContext';

const Storage: FC = (): ReactElement => {
  const { setUniformType, setLogoInfo, setPartInEdition, setShirtInfo, setPantsInfo, setNoPants } =
    useContext(UniformContext);

  useEffect(() => {
    let type = localStorage.getItem('type');
    let logo = localStorage.getItem('logo');
    let partInEdition = localStorage.getItem('partInEdition');
    let shirtInfo = localStorage.getItem('shirtInfo');
    let pantsInfo = localStorage.getItem('pantsInfo');
    let noUniformPants = localStorage.getItem('noPants');

    const uniformType = type ? JSON.parse(type) : 'Men';
    const logo64 = logo ? JSON.parse(logo) : initialState.logoInfo;
    const partEditing = partInEdition ? JSON.parse(partInEdition) : 'Jersey';
    const shirt = shirtInfo ? JSON.parse(shirtInfo) : initialState.shirt;
    const pants = pantsInfo ? JSON.parse(pantsInfo) : initialState.pants;
    const noPants = noUniformPants ? noUniformPants : initialState.noPants;

    setUniformType(uniformType, () => {});
    setLogoInfo(logo64.base64, logo64.filename, () => {});
    setPartInEdition(partEditing, () => {});
    setShirtInfo(shirt, () => {});
    setPantsInfo(pants, () => {});
    setNoPants(noPants === 'true' ? true : false, () => {});
  }, []);

  return <></>;
};

export default Storage;
