import styled from '@emotion/styled';
import { palette, spacing } from 'theme';
import { AppBar } from '@mui/material';

export const StyledHeader = styled(AppBar)({
  height: spacing(10),
  backgroundColor: palette.common.white,
  boxShadow: 'none',
  padding: spacing(0, 3),
  img: {
    height: spacing(5),
  },
});
