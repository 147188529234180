import { useState } from 'react';

import { StyledColorPicker, PickerThumb } from './color-picker.styles';
import { RgbColorPicker } from 'react-colorful';
import { ColorPickerBaseProps, RgbColor } from 'react-colorful/dist/types';
import { Popover, Typography, Stack, SvgIcon } from '@mui/material';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';

type ColorPickerProps = ColorPickerBaseProps<RgbColor> & {
  children?: React.ReactNode;
  label: string;
};

const ColorPicker = ({ label, onChange, color, ...props }: ColorPickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledColorPicker direction="row" justifyContent="space-between" alignItems="center" onClick={handleClick}>
        <Stack direction="row" spacing={1}>
          <PickerThumb pickerColor={color} />
          <Typography>{label}</Typography>
        </Stack>
        <SvgIcon sx={{ fontSize: 18 }}>
          <ChevronDown />
        </SvgIcon>
      </StyledColorPicker>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <RgbColorPicker {...props} color={color} onChange={onChange} />
      </Popover>
    </>
  );
};

export default ColorPicker;
