/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';

type GLTFResult = GLTF & {
  nodes: {
    shoe: THREE.Mesh;
    shoe_1: THREE.Mesh;
    shoe_2: THREE.Mesh;
    shoe_3: THREE.Mesh;
    shoe_4: THREE.Mesh;
    shoe_5: THREE.Mesh;
    shoe_6: THREE.Mesh;
    shoe_7: THREE.Mesh;
  };
  materials: {
    laces: THREE.MeshStandardMaterial;
    mesh: THREE.MeshStandardMaterial;
    caps: THREE.MeshStandardMaterial;
    inner: THREE.MeshStandardMaterial;
    sole: THREE.MeshStandardMaterial;
    stripes: THREE.MeshStandardMaterial;
    band: THREE.MeshStandardMaterial;
    patch: THREE.MeshStandardMaterial;
  };
};

export default function ShoeDraco({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef(null);
  const { nodes, materials } = useGLTF('/models/shoe-draco.glb') as GLTFResult;
  const skinMap = useLoader(TextureLoader, '/BrickWall29_4K_AO.png');

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.shoe.geometry} material={materials.laces} />
      <mesh geometry={nodes.shoe_1.geometry} material={materials.mesh} receiveShadow castShadow>
        <meshStandardMaterial color="#f00f00" map={skinMap} roughnessMap={skinMap} />
      </mesh>
      <mesh geometry={nodes.shoe_2.geometry} material={materials.caps} />
      <mesh geometry={nodes.shoe_3.geometry} material={materials.inner} />
      <mesh geometry={nodes.shoe_4.geometry} material={materials.sole} />
      <mesh geometry={nodes.shoe_5.geometry} material={materials.stripes} />
      <mesh geometry={nodes.shoe_6.geometry} material={materials.band} />
      <mesh geometry={nodes.shoe_7.geometry} material={materials.patch} />
    </group>
  );
}

useGLTF.preload('/shoe-draco.glb');
