import type { RgbColor } from 'react-colorful/dist/types';
import UniformActionsTypes from 'context/actions/types/uniform';

export type UniformTypes = 'Women' | 'Men';
export type UniformParts = 'Jersey' | 'Pants';

export interface CustomerInfo {
  name: string;
  email: string;
  organization: string;
  state: string;
  city: string;
  phone: string;
}

export interface OrderInfo {
  quantity: number;
  additionalInfo: string;
}

export type UniformpieceType = {
  primaryColor: RgbColor;
  secondaryColor: RgbColor;
  screenShot: string;
  hasSecondaryColor?: boolean;
};

export type UniformsContextType = {
  uniformType: UniformTypes;
  partInEdition: UniformParts;
  noPants: boolean;
  logoInfo: {
    base64: string;
    filename: string;
  };
  shirt: UniformpieceType;
  pants: UniformpieceType;
};

export const initialState: UniformsContextType = {
  uniformType: 'Men',
  partInEdition: 'Jersey',
  noPants: false,
  logoInfo: {
    base64: '',
    filename: '',
  },
  shirt: {
    primaryColor: { r: 255, g: 255, b: 255 },
    secondaryColor: { r: 0, g: 0, b: 0 },
    screenShot: '',
  },
  pants: {
    primaryColor: { r: 255, g: 255, b: 255 },
    secondaryColor: { r: 0, g: 0, b: 0 },
    screenShot: '',
  },
};

export const uniformReducer = (state = initialState, action: any): UniformsContextType => {
  switch (action.type) {
    case UniformActionsTypes.SET_UNIFORM_RESET_STATE:
      localStorage.removeItem('type');
      localStorage.removeItem('logo');
      localStorage.removeItem('shirtInfo');
      localStorage.removeItem('pantsInfo');
      localStorage.removeItem('partInEdition');
      localStorage.removeItem('noPants');
      return initialState;
    case UniformActionsTypes.SET_UNIFORM_TYPE:
      localStorage.setItem('type', JSON.stringify(action.payload));
      return {
        ...state,
        uniformType: action.payload,
      };
    case UniformActionsTypes.SET_UNIFORM_SHIRT_COLOR:
      const newShirtFromShirtState = {
        primaryColor: action.payload.isPrimary ? action.payload.color : state.shirt.primaryColor,
        secondaryColor: !action.payload.isPrimary ? action.payload.color : state.shirt.secondaryColor,
        screenShot: state.shirt.screenShot,
      };
      const newPantsFromShirtState = {
        primaryColor: action.payload.isPrimary ? action.payload.color : state.shirt.primaryColor,
        secondaryColor: !action.payload.isPrimary ? action.payload.color : state.pants.secondaryColor,
        screenShot: state.shirt.screenShot,
      };
      localStorage.setItem('shirtInfo', JSON.stringify(newShirtFromShirtState));
      localStorage.setItem('pantsInfo', JSON.stringify(newPantsFromShirtState));
      return {
        ...state,
        shirt: newShirtFromShirtState,
        pants: newPantsFromShirtState,
      };
    case UniformActionsTypes.SET_UNIFORM_PANTS_COLOR:
      const { isPrimary, color } = action.payload;
      const newPantsFromPantsState = {
        primaryColor: isPrimary ? color : state.pants.primaryColor,
        secondaryColor: !isPrimary ? color : state.pants.secondaryColor,
        screenShot: state.shirt.screenShot,
      };
      localStorage.setItem('pantsInfo', JSON.stringify(newPantsFromPantsState));
      return {
        ...state,
        pants: newPantsFromPantsState,
      };

    case UniformActionsTypes.SET_UNIFORM_SHIRT_SCREENSHOT:
      const newShirtFromScreenshotState = {
        primaryColor: state.shirt.primaryColor,
        secondaryColor: state.shirt.secondaryColor,
        screenShot: action.payload,
      };
      localStorage.setItem('shirtInfo', JSON.stringify(newShirtFromScreenshotState));
      return {
        ...state,
        shirt: newShirtFromScreenshotState,
      };
    case UniformActionsTypes.SET_UNIFORM_PANTS_SCREENSHOT:
      const newPantsFromScreenshotState = {
        primaryColor: state.pants.primaryColor,
        secondaryColor: state.pants.secondaryColor,
        screenShot: action.payload,
      };
      localStorage.setItem('pantsInfo', JSON.stringify(newPantsFromScreenshotState));
      return {
        ...state,
        pants: newPantsFromScreenshotState,
      };
    case UniformActionsTypes.SET_UNIFORM_SHIRT_INFO:
      return {
        ...state,
        shirt: action.payload,
      };
    case UniformActionsTypes.SET_UNIFORM_PANTS_INFO:
      return {
        ...state,
        pants: action.payload,
      };
    case UniformActionsTypes.SET_UNIFORM_PART_EDITING:
      localStorage.setItem('partInEdition', JSON.stringify(action.payload));
      return {
        ...state,
        partInEdition: action.payload,
      };
    case UniformActionsTypes.SET_UNIFORM_LOGO:
      localStorage.setItem('logo', JSON.stringify(action.payload));
      return {
        ...state,
        logoInfo: action.payload,
      };
    case UniformActionsTypes.SET_UNIFORM_NO_PANTS:
      localStorage.setItem('noPants', action.payload);
      return {
        ...state,
        noPants: action.payload,
      };
    default:
      return state;
  }
};
