import { styled, Drawer, Button, Stack, DrawerProps } from '@mui/material';

export const StyledDrawer = styled(Drawer)<DrawerProps>(({ variant, theme: { spacing } }) => ({
  flexShrink: 0,
  width: spacing(45),
  '.MuiDrawer-paper': {
    width: variant === 'permanent' ? spacing(45) : '100%',
    padding: variant === 'permanent' ? spacing(3, 3, 10) : spacing(3),
  },
  '.absolute-button': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderRadius: 0,
    width: '100%',
    padding: spacing(3, 0),
  },
}));

export const StyledLogoBox = styled('div')(({ theme: { palette, spacing } }) => ({
  height: 120,
  padding: spacing(2),
  border: `2px solid ${palette.grey[300]}`,
  borderRadius: 8,
  img: {
    width: '100%',
    height: '100%',
  },
}));

export const AbsoluteButton = styled(Button)(({ theme: { spacing } }) => ({}));

export const HiddenInput = styled('input')({
  position: 'absolute',
  textIndent: '-99999px',
  opacity: 0,
  visibility: 'hidden',
});

export const MobileButtonsContainer = styled(Stack)({
  position: 'fixed',
  width: '100%',
  bottom: 0,
  padding: 12,
});

export const StyledEditButton = styled(Button)(({ theme: { spacing } }) => ({
  borderRadius: '50%',
  padding: spacing(2),
  fontSize: '1em',
  minWidth: 0,
  svg: {
    width: '1em',
    height: '1em',
  },
}));
