import type { PaletteOptions } from '@mui/material';

const basePalette: PaletteOptions = {
  common: {
    white: '#ffffff',
    black: '#000000',
  },
  primary: {
    main: '#4361EE',
    light: '#4895ef',
    dark: '#2741b9',
  },
  error: {
    main: '#ea1043',
    light: '#f8b4c4',
    dark: '#d70f3e',
  },
  info: {
    main: '#1756e8',
    light: '#b9ccf8',
    dark: '#154dd1',
  },
  warning: {
    main: '#eb6600',
    light: '#f9cfae',
    dark: '#d65d00',
  },
  success: {
    main: '#058f48',
    light: '#b2dcc6',
    dark: '#058040',
  },
  text: {
    primary: '#000000',
  },
  grey: {
    50: '#f2f1f3',
    100: '#d9d8dc',
    300: '#adabb3',
    500: '#62667A',
    700: '#5e5c64',
    900: '#37363b',
  },
  lightBackground: {
    primary: '#eeeafb',
    success: '#e6f4ed',
    warning: '#fdf1e7',
    error: '#fde7ec',
    info: '#e8eefd',
    gray: '#C9CDE0',
  },
  action: {
    hover: '#f2f1f3',
  },
};

export default basePalette;
