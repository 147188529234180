import { Stack, styled } from '@mui/material';
import { RgbColor } from 'react-colorful/dist/types';

type PickerThumbProps = {
  pickerColor: RgbColor;
};

export const StyledColorPicker = styled(Stack)(({ theme: { palette, spacing } }) => ({
  border: `1px solid ${palette.grey[100]}`,
  height: spacing(6),
  padding: 10,
  borderRadius: 4,
  cursor: 'pointer',
}));

export const PickerThumb = styled('div', {
  shouldForwardProp: (prop) => prop !== 'pickerColor',
})<PickerThumbProps>(({ theme: { palette }, pickerColor }) => ({
  width: 24,
  height: 24,
  backgroundColor: `rgb(${pickerColor.r}, ${pickerColor.g}, ${pickerColor.b})`,
  borderRadius: 8,
  border: `2px solid ${palette.grey[50]}`,
}));
